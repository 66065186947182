import React from "react";
import { graphql, StaticQuery } from "gatsby";
import {
  Navigation,
  Footer,
  CopyrightLabel,
  SiteMap,
  SocialMedia,
  MessageDisplay,
} from "@fishrmn/fishrmn-components";
import { Link } from "gatsby";
import "semantic-ui-less/semantic.less";
import "./layout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        fishermanWebsiteLayout(
          name: { eq: "@" }
          components: { elemMatch: { fastId: { eq: "MessageDisplay" } } }
        ) {
          components {
            data
          }
        }

        allFisherman {
          edges {
            node {
              businessName
              socialMedia {
                link
                type
              }
              logo
            }
          }
        }
      }
    `}
    render={(queryData) => {
      const data = queryData.allFisherman.edges[0].node;

      return (
        <div className="site-container">
          <div>
            <MessageDisplay
              businessName={data.businessName}
              {...JSON.parse(
                queryData.fishermanWebsiteLayout.components[0].data
              )}
            />

            <Navigation
              links={[
                { pageTitle: "Home", slug: "/", internal: true },
                { pageTitle: "Contact", slug: "/contact/", internal: true },
                {
                  pageTitle: "Book Appointment",
                  slug: " https://beaconhill.fivease.com/online/bookme",
                  internal: false,
                },
              ]}
              backgroundColor={"white"}
              bordered={false}
              centerMobileNavItems={true}
              evenSpacing={true}
              fixed={false}
              header={data.businessName}
              linksAs={"text"}
              logo={data.logo}
              primaryContentCentered={false}
              internalLinkComponent={Link}
            />
          </div>
          <div className="page-container">{children}</div>
          <Footer
            backgroundColor={"primary"}
            horizontalAlign={"center"}
            verticalAlign={"middle"}
            columns={[
              <CopyrightLabel
                company={data.businessName}
                phrase={"All Rights Reserved"}
              />,
              <SiteMap
                links={[
                  { pageTitle: "Home", slug: "/", internal: true },
                  { pageTitle: "Contact", slug: "/contact/", internal: true },
                  {
                    pageTitle: "Book Appointment",
                    slug: " https://beaconhill.fivease.com/online/bookme",
                    internal: false,
                  },
                ]}
                horizontal={true}
                bulleted={false}
                internalLinkComponent={Link}
              />,
              <SocialMedia
                socialMediaValues={data.socialMedia}
                buttonType={"circle"}
                centerHeader={false}
                colors={"secondary"}
                groupButtons={false}
                groupVertically={false}
                header={""}
                inverted={false}
                labelPosition={"free"}
                showLabels={false}
              />,
            ]}
          />
        </div>
      );
    }}
  />
);
