export function getPageHeroImages(components) {
  const placeholder = 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png';
  const heroElementComponents = components.filter(({ fastId }) => fastId === 'HeroElement');

  if (heroElementComponents.length === 0) {
    return [placeholder];
  }

  const heroImageFiles = heroElementComponents[0].files.map(({ file }) => file);

  return heroImageFiles.length > 0
    ? heroImageFiles
    : [placeholder];
}

export function getPageGalleryImages(components) {
  const imageCollectionComponents = components.filter(({ fastId }) => fastId === 'ImageCollection');

  return imageCollectionComponents.length > 0
    ? imageCollectionComponents[0].files.map(({ file }) => file)
    : [];
}